header{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../assets/images/header_main_img.jpg") center/cover no-repeat;
}

.header-content .text-lead{
    /* font-size: 18px; */
    color: white;
    text-align: center !important;
    margin: 3.2rem 0 4.9rem 0;
}
.header-title{
    text-align: center;
    font-size: 50px;
    font-weight: 900;
    line-height: 1.2;
    background: linear-gradient(to right, var(--clr-robin-blue), var(--clr-crazy-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title-pop{
    line-height: 1.2;
    background: linear-gradient(to right, var(--clr-robin-blue), var(--clr-crazy-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header-btn{
    align-self: center!important;
}
.header-btn span{
    margin-left: 10px;
}

@media screen and (min-width: 600px){
    .header-title{
        /* text-align: end; */
        font-size: 88px;
    }
    .header-content *{
        max-width: 920px;
    }
}